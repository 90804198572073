import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showCRMenu: false, //  menu選單
    showCRShowEmailPop: false, // 驗證信箱pop
    showCRSubscribePop: false, // 訂閱電子報pop
    showCREmail: '', // 驗證信箱
    showCRCancelled: false, // 成功退訂電子報頁
};

export const cryptoReadSlice = createSlice({
  name: "cryptoRead",
  initialState,
  reducers: {
    setCRMenu: (state, action) => {
      state.showCRMenu = action.payload;
    },
    setCRShowEmailPop: (state, action) => {
      state.showCRShowEmailPop = action.payload;
    },
    setCRShowSubscribePop: (state, action) => {
      state.showCRSubscribePop = action.payload;
    },
    setCREmail: (state, action) => {
      state.showCREmail = action.payload;
    },
    setShowCRCancelled: (state, action) => {
      state.showCRCancelled = action.payload;
    }
  },
});

export const cryptoReadState = (state) => state.cryptoRead;

export const {
    setCRMenu,
    setCRShowEmailPop,
    setCRShowSubscribePop,
    setCREmail,
    setShowCRCancelled,
} = cryptoReadSlice.actions;

export default cryptoReadSlice.reducer;
