// 複製提示
"use client";
import { useEffect } from "react";
import Image from "next/image";

const CopyHint = ({ t, dispatch, setCopyHint, showCopyHint, hintText }) => {
  useEffect(() => {
    if (showCopyHint) {
      setTimeout(() => {
        dispatch(setCopyHint(false));
      }, 3000);
    }
  }, [showCopyHint]);

  return (
    <div
      className={`copy trans fixed left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center gap-x-[14px] rounded-[4px] bg-opacity-[.85] bg-[#fff]
      dark:bg-[#1e1e1e] backdrop-blur-[10px] px-[20px] py-[16px] border-1 border-black1
    ${
      showCopyHint
        ? "opacity-[1] z-[10000] top-[50%]"
        : "opacity-0 z-[-1] top-[52%]"
    }`}
    >
      <div className="img-box w-[32px] h-[32px] after:content-[none] dark:filter-white">
        <Image
          width={32}
          height={32}
          src="/images/icon/icon_copy.svg"
          alt="copy"
          loading="lazy"
        />
      </div>
      <span className="text-bd1m text-black9">{t(hintText)}</span>
    </div>
  );
};

export default CopyHint;
