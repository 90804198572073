// 登入彈窗
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoginModal } from "@/slices/popSlice";
import { popState } from "@/slices/popSlice";
import { userLogin, userState } from "@/slices/userSlice";

import Image from "next/image";
import EmailLogin from "./EmailLogin"; // email 登入

const LOGIN_PROVIDERS = {
  APPLE: "apple",
  GOOGLE: "google",
  EMAIL: "email",
};

const THIRD_PARTY_LOGIN_PROVIDERS = [
  LOGIN_PROVIDERS.APPLE,
  LOGIN_PROVIDERS.GOOGLE,
];

const LoginModal = ({ t }) => {
  const { user } = useSelector(userState);
  const { showLoginModal } = useSelector(popState);
  const dispatch = useDispatch();

  const [loginStep, setLoginStep] = useState(1); // 登入步驟

  //   按下登入
  const onSignIn = async (provider) => {
    // 步驟二填mail
    if (provider === LOGIN_PROVIDERS.EMAIL) setLoginStep(2);

    // 第三方登入
    if (THIRD_PARTY_LOGIN_PROVIDERS.includes(provider))
      window.location = `/api/v1/auth/signin/${provider}?originalUrl=${window.location.href}`;
  };

  return (
    <>
      <div
        className={`login-modal fixed flex trans w-screen h-screen top-0 left-0 justify-center items-center moving-element
            ${showLoginModal ? "z-[80] opacity-[1]" : "opacity-0 z-[-1]"}`}
      >
        <div className="relative z-[2] max-w-[95vw] max-h-[90vh] noScrollY shadow-[0px_4px_10px_0px_rgba(0,0,0,.14)] rounded-[12px]">
          <div
            className={`flex ${
              loginStep === 1 ? "w-[816px]" : "w-[880px]"
            } h-full relative z-[1] max-h-[90vh] noScrollY max-w-[95vw] rounded-[12px] umd:block 
          [&>div]:bg-[#fff] [&>div]:flex-grow [&>div]:px-[50px] [&>div]:py-[50px] [&>div]:min-h-[460px] [&>div]:flex-shrink-0 usm:[&>div]:px-[30px]`}
          >
            <div className="w-[40%] max-w-[396px] flex-shrink !py-0 !px-0 relative umd:!hidden !bg-transparent umd:z-[-10] umd:w-[0] umd:h-[0] overflow-hidden">
              <video
                loop
                className="w-full h-[calc(100%+2px)] object-cover object-center relative z-[0] umd:hidden umd:z-[-10]"
                src="/images/video/login_video.mp4"
                autoPlay
                muted
                playsInline
              />
              <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1] max-w-[325px] w-[90%] aspect-[325/147]">
                <div className="img-box w-full h-full after:content-none">
                  <Image
                    width={325}
                    height={147}
                    src="/images/layout/login_left_logo.svg"
                    alt="login_logo"
                    loading="lazy"
                    sizes="300px"
                  />
                </div>
              </div>
            </div>

            {loginStep === 1 && (
              <div className="px-[50px] py-[55px] flex-grow usm:px-[30px] usm:py-[35px]">
                <div className="img-box w-[225px] h-[51px] mx-auto max-w-[90%] aspect-[225/51] after:content-none">
                  <Image
                    sizes="100vw"
                    fill
                    className="!object-contain object-center"
                    src="/images/layout/logo.svg"
                    alt="logo"
                  />
                </div>
                <p className="text-bd4n text-[#141414] w-full text-center mt-[34px]">
                  {t("CONTINUE")}
                </p>

                <div className="button-box overflow-hidden space-y-[12px] my-[28px]">
                  {/* google */}
                  <div onClick={() => onSignIn("google", "google")}>
                    <div className="login-button group hover:text-[#fff] hover:border-transparent hover:bg-[#CA5040] text-[#141414]">
                      <Image
                        width={20}
                        height={20}
                        className="trans relative z-[1] group-hover:opacity-0"
                        src="/images/login/icon_google.svg"
                        alt="google"
                      />
                      <Image
                        width={20}
                        height={20}
                        className="absolute opacity-0 trans group-hover:opacity-[1] w-[20px] h-[20px]"
                        src="/images/login/icon_white_google.png"
                        alt="google"
                      />
                      Google
                    </div>
                  </div>

                  {/* apple */}
                  <div onClick={() => onSignIn("apple", "apple")}>
                    <div className="login-button group hover:text-[#fff] hover:border-transparent hover:bg-[#1e1e1e] text-[#141414]">
                      <Image
                        width={20}
                        height={20}
                        className="trans w-[20px] h-[20px] group-hover:filter-white"
                        src="/images/login/icon_black_apple.svg"
                        alt="apple"
                      />
                      Apple
                    </div>
                  </div>

                  {/* mail */}
                  <div onClick={() => onSignIn("email", "mail")}>
                    <div className="login-button group hover:text-[#1e1e1e] hover:border-transparent hover:bg-[#E1F4ED] text-[#141414]">
                      <Image
                        width={20}
                        height={20}
                        className="trans w-[20px] h-[20px]"
                        src="/images/login/icon_mail.svg"
                        alt="mail"
                      />
                      Email
                    </div>
                  </div>
                </div>

                <p className="text-bd3n text-[#1e1e1e] text-center omd:mt-[25px]">
                  {t("AGREE")}{" "}
                  <a
                    className="text-pale border-b-1 border-pale trans hover:text-pale8 hover:border-pale8"
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener"
                  >
                    {t("TERMS")}
                  </a>
                </p>
              </div>
            )}

            {/* email登入流程 */}
            <EmailLogin
              loginStep={loginStep}
              setLoginStep={setLoginStep}
              dispatch={dispatch}
              showLoginModal={showLoginModal}
              setLoginModal={setLoginModal}
              t={t}
              user={user}
              userLogin={userLogin}
            />
          </div>
        </div>

        <div
          className="absolute top-0 left-0 w-full h-full z-[1]"
          onClick={() => dispatch(setLoginModal(false))}
        />
      </div>

      {showLoginModal && (
        <div className="fade-in fixed w-full h-full top-0 left-0 bg-[rgb(0,0,0)] bg-opacity-[.7] backdrop-blur-[2px] trans z-[79]" />
      )}
    </>
  );
};

export default LoginModal;
