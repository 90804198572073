// 語言切換＋下載APP
import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'next-themes';
import i18nConfig from '@/i18nConfig';

import Image from 'next/image';
import { CRYPTO_CITY_OFFICIAL_URL } from '@/common/constant';

const LanguageAndApp = ({ locale, t, type }) => {
    const { i18n } = useTranslation();
    const currentLocale = i18n.language;
    const { theme, setTheme } = useTheme(); // 切換黑白模式
    const router = useRouter();
    const pathname = usePathname();

    const [isMount, setIsMount] = useState(false);

    // 切換夜間模式
    const handleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    //   語言切換
    const onLocaleChange = () => {
        const newLocale = locale === 'cn' ? 'tw' : 'cn';
        // set cookie for next-i18n-router
        const days = 30;
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = date.toUTCString();
        document.cookie = `NEXT_LOCALE=${newLocale};expires=${expires};path=/`;

        // redirect to the new locale path
        if (currentLocale === i18nConfig.defaultLocale && !i18nConfig.prefixDefault) {
            router.push(
                '/' + newLocale + pathname
                // { scroll: false }
            );
        } else {
            router.push(
                pathname.replace(`/${currentLocale}`, `/${newLocale}`)
                //  {scroll: false,}
            );
        }

        router.refresh();
    };

    useEffect(() => {
        if (!isMount) setIsMount(true);
    }, []);

    return (
        <div
            className={`flex items-center space-x-[10px] [&>*]:border-1 [&>*]:border-black3 [&>*]:rounded-[40px]
        [&>*]:px-[12px] [&>*]:h-[32px] text-black9 text-btn2 [&>*]:trans
        dark:[&>*]:border-dblack3
          ${
              type === 'pc'
                  ? 'uxl:hidden'
                  : 'uxl:flex-col uxl:gap-y-[10px] uxl:py-[24px] uxl:border-t-1 uxl:border-b-1 uxl:border-black2 uxl:space-x-0 uxl:px-[10px]'
          }`}
        >
            {/* 切換語言 */}
            <button
                onClick={onLocaleChange}
                className="group flex items-center space-x-[8px] hover:bg-black hover:text-white
         uxl:py-[18px]
        uxl:justify-center uxl:w-[200px] uxl:mx-auto uxl:border-none uxl:bg-white"
            >
                <Image
                    className="group-hover:filter-white trans dark:filter-grey dark:group-hover:filter-black
          uxl:w-[14px] uxl:h-[14px]"
                    width={22}
                    height={22}
                    src="/images/icon/icon_language.svg"
                    alt="toggle-language-button"
                />
                <span className="uxl:hidden">{locale === 'tw' ? '简中' : '繁中'}</span>
                <span className="hidden uxl:inline-block">
                    {locale === 'tw' ? '简体中文' : '繁體中文'}
                </span>
            </button>

            {/* 手機版切換顏色模式 */}
            <button
                onClick={handleTheme}
                className="hidden group items-center space-x-[8px] hover:bg-black hover:text-white py-[18px] justify-center w-[200px] mx-auto 
                border-none bg-white uxl:flex [&>img]:trans"
            >
                <Image
                    className="group-hover:filter-white dark:filter-grey dark:group-hover:filter-black dark:hidden"
                    width={16}
                    height={16}
                    src="/images/icon/icon_light.svg"
                    alt="toggle-mode-button"
                />
                <Image
                    className="group-hover:filter-white dark:filter-grey dark:group-hover:filter-black hidden dark:block"
                    width={16}
                    height={16}
                    src="/images/icon/icon_dark.svg"
                    alt="toggle-mode-button"
                />

                {isMount && (
                    <span>
                        {theme === 'light'
                            ? locale === 'tw'
                                ? '淺色模式'
                                : '浅色模式'
                            : '深色模式'}
                    </span>
                )}
            </button>

            {/* 下載APP */}
            <a
                className="hover:bg-black hover:text-white uxl:hidden flex items-center"
                href={CRYPTO_CITY_OFFICIAL_URL.APP}
                target="_blank"
                rel="noreferrer noopener"
            >
                {t('DOWNLOAD')}
            </a>
        </div>
    );
};

export default LanguageAndApp;
